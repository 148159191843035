<template>
  <!--begin::Basic info-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 w-100 d-flex align-items-center justify-content-between">
        <div
          class="d-flex align-items-center justify-content-start"
          @click="backMenu"
        >
          <i class="bi bi-arrow-left h3 me-4 back-menu text-white"></i>
          <h3 class="fw-bolder m-0 text-white">
            Detail Absensi Kehadiran
          </h3>
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9 strip-bg">
            <div class="d-flex flex-wrap py-5">
              <div class="flex-equal me-5">
        <div class="mb-10">
          <h5 class="mb-1">Detail:</h5>
          <div class="d-flex flex-wrap py-5">
            <div class="flex-equal me-5">
              <table class="table fs-6 fw-semobold gs-0 gy-2 gx-2 m-0">
                <tr>
                  <td class="text-gray-400 min-w-175px w-175px">Nama Auditor:</td>
                  <td class="text-gray-800">{{ detail.person.nik }}</td>
                </tr>
                <tr>
                  <td class="text-gray-400 min-w-175px w-175px">Nama Apotek:</td>
                  <td class="text-gray-800">{{ detail.person.name }}</td>
                </tr>
                <tr>
                  <td class="text-gray-400">Alamat:</td>
                  <td class="text-gray-800">{{ detail.person.territory_name }}, {{ detail.person.kecamatan_name }} - {{ detail.person.kabupaten_name }}, {{ detail.person.provinsi_name }}</td>
                </tr>
                <tr>
                  <td class="text-gray-400">Waktu Terkirim:</td>
                  <td class="text-gray-800">{{ detail.person.createdAt }}</td>
                </tr>
                <tr>
                  <td class="text-gray-400">Titik lokasi:</td>
                  <td class="text-gray-800" @click="maps({latitude: detail.person.latitude, longitude: detail.person.longitude })">
                      {{ detail.person.latitude }}, {{ detail.person.longitude }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- <h5 class="mb-1">Petugas:</h5>
          <div class="d-flex flex-wrap py-5">
            <div class="flex-equal me-5">
              <table class="table fs-6 fw-semobold gs-0 gy-2 gx-2 m-0">
                <tr>
                  <td class="text-gray-400 min-w-175px w-175px">Nama:</td>
                    <td class="text-gray-800">{{ detail.created_by.name }}</td>
                </tr>
                <tr>
                  <td class="text-gray-400">Terdaftar:</td>
                  <td class="text-gray-800">{{ detail.created_by.createdAt }}</td>
                </tr>
              </table>
            </div>
          </div> -->
        </div>
              </div>

          <div class="d-flex flex-wrap">
            <div class="flex-equal">
              <div class="mb-10">
                <h5 class="mb-5">Foto Didepan Apotek:</h5>
                <img class="img-fluid" :src="detail.person.foto" alt="foto kegiatan" style="max-width: 300px" />
              </div>
            </div>
            <div class="flex-equal">
              <div class="mb-10">
                <h5 class="mb-5">Foto Dengan Petugas:</h5>
                <img class="img-fluid" :src="detail.person.foto2" alt="foto kegiatan" style="max-width: 300px" />
              </div>
            </div>
          </div>
            </div>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Absensi Kehadiran",
  props: ["id"],
  setup(props) {
    setCurrentPageTitle("Absensi Kehadiran");

    const store = useStore();
    const router = useRouter();

    store.commit("SET_ACTIVE_MENU", "figure-map");
    store.dispatch("getDetailPersonDashboard", props.id);

    const detail = computed(() => store.state.PersonDashboardModule.detail);

    const maps = (data) => {
      window.open(
        "https://maps.google.com/?q=" + data.latitude + "," + data.longitude,
        "_blank"
      );
    };

    const backMenu = () => {
      router.push({ name: "person-map-pro" });
    };

    return {
      maps,
      backMenu,
      detail,
    };
  },
});
</script>
